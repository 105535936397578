<template>
  <div class="home">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="content">
      <div class="caseExhibition_box">
        <Title title="案例展示" />
        <div class="caseExhibitions">
          <div
            v-for="(item, index) in caseExhibitions"
            :key="index"
            class="item"
            @click="onExhibitions(index)"
          >
            <div class="img_content">
              <img :src="item.imgUrl" alt="" />
              <div class="tit_content" v-show="selecteds.indexOf(index) !== -1">
                <div
                  v-for="(item2, index2) in item.content"
                  :key="index2"
                  class="item_content"
                >
                  <h3 v-if="item2.title">{{ item2.title }}</h3>
                  <p v-for="(item3, index3) in item2.list" :key="index3">
                    {{ item3 }}
                  </p>
                </div>
              </div>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="introduce_box">
        <Title title="流程介绍" />
        <div class="introduces">
          <div
            :key="index"
            class="item"
            :class="index % 2 === 0 ? 'left' : 'right'"
            v-for="(item, index) in introduces"
          >
            <img class="img" :src="item.imgUrl" alt="" />
            <div class="title">
              <h3>{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage_box">
        <Title title="我们的优势" />
        <div class="advantages">
          <div v-for="(item, index) in advantages" :key="index" class="item">
            <img
              :src="
                require('@/assets/images/home/advantage/icon' +
                  index +
                  '_0.png')
              "
              alt=""
            />
            <div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer2 />
  </div>
</template>

<script>
import {
  caseExhibitions,
  introduces,
  advantages,
} from "@/assets/js/staticMobile.js";
export default {
  name: "Home",
  data() {
    return {
      caseExhibitions,
      introduces,
      advantages,
      selecteds: [],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/1.png"),
        },
        {
          imgUrl: require("@/assets/images/carousels/2.png"),
        },
        {
          imgUrl: require("@/assets/images/carousels/3.png"),
        },
        {
          imgUrl: require("@/assets/images/carousels/4.png"),
        },
      ],
    };
  },
  methods: {
    onExhibitions(index) {
      if (this.selecteds.indexOf(index) === -1) {
        this.selecteds.push(index);
      } else {
        this.selecteds.splice(this.selecteds.indexOf(index), 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  .content {
    .caseExhibition_box {
      margin-top: 20px;
      .caseExhibitions {
        .item {
          width: 100%;
          margin: 18px auto 0;
          .img_content {
            position: relative;
            > img {
              width: 100%;
              display: block;
            }
            .tit_content {
              position: absolute;
              top: 0;
              background-color: #0000008c;
              height: 100%;
              padding: 0 22px;
              color: #fff;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .item_content {
                h3 {
                  font-size: 15px;
                  line-height: 32px;
                  font-weight: 400;
                  font-size: 15px;
                  font-family: "AlibabaPuHuiTi-Regular";
                }
                p {
                  line-height: 20px;
                  text-align: left;
                  font-weight: 200;
                  font-size: 14px;
                }
              }
            }
          }
          .name {
            font-size: 15px;
            line-height: 44px;
            color: #000;
            font-weight: 200;
            box-shadow: 0px 0px 5px #eeeeee;
          }
        }
      }
    }
    .introduce_box {
      margin-top: 15px;
      height: 625px;
      padding: 45px 18px 0;
      background: linear-gradient(180deg, #ffffff 0%, #ddeaff 101%);
      .introduces {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          display: flex;
          align-items: center;
          height: 68px;
          .img {
            height: 71px;
            width: 100px;
          }
          .title {
            color: #333;
            > h3 {
              font-size: 15px;
              line-height: 20px;
              font-weight: 400;
            }
            p {
              margin-top: 5px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 200;
            }
          }
        }
        .item + .item {
          margin-top: 12px;
        }
        .left {
          .title {
            text-align: left;
            margin-left: 5px;
          }
        }
        .right {
          flex-direction: row-reverse;
          .title {
            text-align: right;
            margin-right: 5px;
          }
        }
      }
    }
    .advantage_box {
      margin-top: 40px;
      margin-bottom: 30px;
      .advantages {
        display: flex;
        flex-direction: column;
        height: 470px;
        padding: 0 18px;
        .item {
          margin-top: 12px;
          padding: 0 18px;
          height: 95px;
          background-color: #fff;
          box-shadow: 0px 0px 5px #006eff33;
          border-radius: 2px;
          display: flex;
          align-items: center;
          > img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
          > div {
            h3 {
              font-size: 15px;
              line-height: 25px;
              font-weight: 700;
              color: #006eff;
              text-align: left;
              font-family: "AlibabaPuHuiTi-Bold";
            }
            p {
              font-size: 14px;
              line-height: 20px;
              font-weight: 200;
              color: #333333;
              text-align: left;
              font-family: "AlibabaPuHuiTi-Light";
            }
          }
        }
      }
    }
  }
}
</style>
